<template>
  <div class="col-3 col header">
    <div class="identity">
      <a class="logo" href="#" @click.prevent="goHome">
        <svg-logo></svg-logo>
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import Logo from '@/assets/svgs/logo.vue'

export default {
  name: 'header-logo',
  methods: {
    goHome() {
      this.$router.push({ name: 'Home' })
    },
  },
  components: {
    svgLogo: Logo,
  },
  computed: {
    ...mapGetters({
      userLogin: 'user/userLogin',
    }),
    isUSA() {
      return !!this.userLogin && !!this.userLogin.membertype && ['USA', 'usa'].includes(this.userLogin.membertype)
    }
  },

}
</script>

<style scoped lang="scss">
@import '@/styles/settings.scss';

.identity {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    justify-content: center;
  }
}

.logo {
  max-width: 210px;
  margin-right: 30px;

  @include breakpoint(sm) {
    margin-right: 0;
  }

  svg {
    display: block;
    height: auto;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 170px;
    }
  }
}

.btn {
  @include breakpoint(sm) {
    display: none;
  }

  &.btn-secondary {
    &.btn-outline {
      padding: 10px 15px;
      background-color: transparent;
      border: 3px solid $secondary-color;
      color: $secondary-color;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 3.5px;
      line-height: 19px;

      @include breakpoint(sm) {
        padding: 10px;
        font-size: 13px;
        letter-spacing: 3.2px;
        line-height: 18px;
      }

      &:hover {
        background-color: $secondary-color;
        color: #fff;
      }
    }
  }
}
</style>
